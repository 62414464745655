$lightYellow: lighten($yellow, 7%);

#aino-checkout .PaymentComponent {
  
  .wrapper {
    margin-top: 1em;
    overflow: hidden;
    padding-bottom: 30px;
    position: relative;
    min-height: calc(130px);
  }

  .klarna {
    width: calc(100% + 30px);
    margin-left: -15px;
    overflow: visible;
    min-height: 700px;
  }

  .misMatchPostalCode {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 40px;
    justify-content: center;
  }

  .backdrop {
    position: absolute;
    top: -18px;
    left: -15px;
    width: calc(100% + 30px);
    height: calc(100% + 18px);
    background: rgba(#fff, 0.9);
  }

  .content {
    width: calc(100% - 80px);
    max-height: 340px;
    overflow: auto;
    border: 1px solid rgba(#000, 0.2);
    box-shadow: rgba(#000, 0.1) 1px 1px 3px;
    border-radius: 4px;
    background: #fff;
    position: relative;
  }

  .innerContent {
    font-size: 0.875em;
    padding: 20px 30px;
    overflow: hidden;
    button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .buttons {
    margin-top: 20px;
    margin-bottom: -5px;
    font-size: 1.125em;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 700px) {
      display: block;
    }
    > div {
      margin-bottom: 10px;
      width: calc(50% - 10px);
      @media all and (max-width: 700px) {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }

  .payment-picker {
    padding: 20px 30px;
    width: calc(100% + 60px);
    left: -30px;
    @media all and (max-width: $mobileBreakpoint) {
      width: calc(100% + 40px);
      left: -20px;
      padding: 20px;
    }
    position: relative;
    border-bottom: 1px solid rgba(#000, 0.1);
    margin-bottom: 20px;
  }

  .errorbox {
    h2 {
      margin-bottom: 10px;
    }
    font-size: 0.75em;
    width: calc(100% + 60px);
    left: -30px;
    @media all and (max-width: $mobileBreakpoint) {
      width: calc(100% + 40px);
      left: -20px;
      padding: 20px;
    }
    padding: 20px 30px;
    position: relative;
    top: -1px;
    .errorbox-close {
      border: none;
      padding: 10px;
      background: none;
      color: inherit;
      cursor: pointer;
      line-height: 0;
      right: 0px;
      top: 4px;
      height: auto;
      position: absolute;
      font-size: 18px;
    }
  }

  .postalcode-error {
    background: desaturate($lightYellow, 30%);
  }

  .unavailable-error {
    h2 {
      font-size: 1em;
      padding-right: 30px;
    }
    background: desaturate($lightYellow, 30%);
    .unavailable-items {
      margin-top: 20px;
      margin-bottom: 10px;
      background: repeating-linear-gradient(
        45deg,
        rgba(#000, 0.01),
        rgba(#000, 0.01) 10px,
        rgba(#fff, 0.6) 10px ,
        rgba(#fff, 0.6) 20px
      );
      padding: 10px;
    }
    .unavailable-item {
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(#000, 0.07);
      &:first-child {
        border-top: none;
      }
      img {
        mix-blend-mode: multiply;
        max-width: 80px;
        height: auto;
        max-height: 80px;
        display: block;
      }
      .unavailable-text {
        font-size: 0.875em;
        margin-left: 20px;
        .price {
          display: block;
          opacity: 0.5;
        }
      }
    }
  }

  .payment-error {
    color: #fff;
    background: $red;
  }

  .redirect {
    position: relative;
    p {
      font-size: 0.675em;
      opacity: 0.6;
      padding: 10px 100px;
      text-align: center;
      @media all and (max-width: $mobileBreakpoint) {
        padding: 10px;
      }
    }
    img {
      max-height: 24px;
      top: -2px;
      position: absolute;
      left: 0;
    }
    .buttonText {
      padding-left: 32px;
    }
    button {
      width: 100%;
      padding: 20px;
      height: auto;
    }
    &.paypal {
      button {
        background-repeat: no-repeat;
        background-size: 40px;
      }
    }
  }
  .billing-address {
    margin-bottom: 20px;
    .AddressComponent {
      .inner {
        padding-bottom: 0;
      }
    }
    > .CheckboxComponent {
      .label {
        font-size: 0.75em;
      }
    }
    .billing-summary {
      margin-top: -8px;
      display: flex;
      align-items: flex-start;
      position: relative;
      .CheckboxComponent {
        margin-top: -6px;
      }
      .edit-button {
        position: absolute;
        opacity: 0.3;
        top: 0;
        right: 0;
        border: none;
        padding: 10px 0 10px 10px;
        background: none;
        color: inherit;
        cursor: pointer;
        line-height: 0;
        height: auto;
        font-size: 0.675em;
      }
    }
  }
}