@import 'src/styles/constants';
@import 'src/styles/mixins';

.checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  cursor: pointer;

  // Label
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;

    &.fullWidth {
      width: 100%;
    }
  }

  &:global(.focus-visible) + label:before {
    box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 2px #666;
  }

  // Label text
  & + label span {
    @include SansSerifText;
    line-height: 1;
    cursor: pointer;
    text-align: left;
  }

  // Box
  & + label:before {
    flex-shrink: 0;
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    border: 1px solid $borderGray;
    border-radius: 1px;
  }

  // Box checked
  &:checked + label:before {
    background: $black;
    border-color: $black;
  }

  &.round {
    & + label:before {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
    &:checked + label:before {
      content: ' ';
      font-family: system-ui;
      color: $white;
      background: $black;
      border: 1px solid currentColor;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: 8px;
      background-position: 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.354 1.354 3 5.707.646 3.354l.708-.708L3 4.293 6.646.646l.708.708Z' fill='%23fff'/%3E%3C/svg%3E");
    }
  }

  &.noCheckedBorder {
    &:checked + label:before {
      border: none !important;
    }
  }

  // Disabled label
  &:disabled + label {
    cursor: auto;
    span {
      opacity: 0.3;
    }
  }
}
