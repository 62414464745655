.errorWrapper {
  background: red;
  height: 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-150%);
  transition: all 0.42s ease-out;
  padding: 0 12px;
  visibility: hidden;
}

.show {
  transform: translateY(0);
  visibility: visible;
  height: 40px;
}

.close {
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  padding: 6px;
}
