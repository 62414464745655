@import './global';
// Globs are not easy to support, let’s do explicit imports!
@import './components/Address.scss';
@import './components/Button.scss';
@import './components/Centra.scss';
@import './components/Checkbox.scss';
@import './components/Choice.scss';
@import './components/CountrySelect.scss';
@import './components/Delivery.scss';
@import './components/GiftCard.scss';
@import './components/Ingrid.scss';
@import './components/Input.scss';
@import './components/Loader.scss';
@import './components/Payment.scss';
@import './components/PaymentPicker.scss';
@import './components/Products.scss';
@import './components/Summary.scss';
@import './components/Totals.scss';
@import './components/Upsale.scss';
@import './screens/Main.scss';
@import './screens/Success.scss';
@import './theme';

#aino-checkout.index {
  > .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  > .error {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .errorbox {
      background: $red;
      color: #fff;
      padding: 30px 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 0;
      p {
        margin: 2px 0 20px;
        font-size: 0.875em;
      }
    }
  }
}
