@import '../../styles/theme';

#aino-checkout .GiftCardComponent {
  form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: top;
    .input {
      flex: 1;
      margin-right: 1em;
    }
    .button {
      margin-top: 9px;
    }
  }
  .success {
    background: $green;
    padding: 14px 10px 14px 20px;
    color: #fff;
    font-size: 0.75em;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-weight: 500;
    button {
      border: none;
      padding: 10px;
      background: none;
      color:  #fff;
      cursor: pointer;
      font-size: 1em;
      line-height: 0;
      right: 0px;
      top: 2px;
      height: auto;
      position: absolute;
    }
  }
  .show {
    border: none;
    padding: 10px 0;
    background: none;
    color: rgba(#000, 0.4);
    text-align: left;
    appearance: none;
    cursor: pointer;
    font-size: 0.725em;
    &:hover {
      color: $secondaryColor;
    }
  }
}