@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transform: translateY(120%);
  transition: transform 0.1s linear 0.3s, background 0.3s $easeOutCubic;
  will-change: transform;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  z-index: 150;
  &.active {
    transition: transform 0.1s linear, background 0.3s $easeOutCubic 0.1s;
    background: rgba(0, 0, 0, 0.2);
    transform: translateY(0%);
  }
}

// For now this comes from each component that uses SlideOver... 
// .wrapper {
//   display: flex;
//   position: fixed;
//   top: 0;
//   box-shadow: 0px -8px 25px rgba(0, 0, 0, 0.05);
//   right: 0;
//   bottom: 0;
//   height: 100%;
//   width: 50%;
//   max-width: 482px;
//   background: $white;
//   color: $black;
//   z-index: 200;
//   transform: translateX(150%); // default style, we will animate with framer motion
//   // transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);

//   // &.active {
//   //   transform: translateX(0);
//   // }

//   @include breakpoint('mobile') {
//     width: calc(100vw - 32px) !important;
//   }

// }

