.wrapper {
  position: fixed;
  top: 120px;
  right: 50px;
  background: #000;
  color: white;
  padding: 0 44px;
  height: 52px;
  z-index: 100;
}
