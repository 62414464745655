@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

#CybotCookiebotDialog {
  box-shadow: none !important;
  border: none !important;
  border-radius: 8px !important;

  * {
    font-family: 'ABC Diatype' !important;
    font-feature-settings: 'ss05' on !important;
    font-weight: 400 !important;
  }

  #CybotCookiebotDialogBodyContent {
    // padding: 2.4rem !important;
    @media (max-width: 600px) {
      // padding: 2.4rem 1.2rem !important;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400 !important;
    // font-size: 1.4rem !important;
    line-height: 1.4em !important;
    font-family: 'Cheltenham' !important;
    text-transform: none !important;
    font-size: 21px;
    letter-spacing: -0.02em !important;
  }

  .CybotCookiebotDialogBodyButton {

  }
  #CybotCookiebotDialogBodyContentTitle {
    margin-bottom: 16px;
  }
}

#CybotCookiebotDialogHeader {
  padding: 16px 24px !important;
  border-color: $borderGray !important;
}

#CybotCookiebotDialogFooter {
  padding: 24px !important;
  @media (max-width: 600px) {
    padding: 24px 12px !important;
  }
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyButton {
  font-family: $fontSerif !important;
  letter-spacing: -0.02em;
  font-size: 21px !important;
  line-height: 1em !important;
  height: 56px !important;
  border-radius: 8px !important;
  padding-top: 2px !important;
  background: transparent !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 0 !important;
  width: 100% !important;
  font-weight: 400 !important;
  text-align: center !important;
  background: $borderGray !important;
  color: $black !important;
  border: none !important;

  &#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll{
    background: $black !important;
    color: $white !important;
  }
  @include breakpoint('mobile') {
    height: 48px !important;
    font-size: 18px !important;
  }

}

#CybotCookiebotDialogNav {
  border: none !important;
  .CybotCookiebotDialogNavItemLink {
    border-bottom: 1px solid $borderGray !important;
  }
}

#CybotCookiebotDialogNav
  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-width: 1px !important;
  border-color: $black !important;
  color:$black !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialogDetailBodyContentTextAbout {
  font-family: var(--font-serif) !important;
  font-weight: 400 !important;
  font-size: 16px !important; 
  line-height: 1.5em !important;
  letter-spacing: -0.01em;
}
#CybotCookiebotDialogDetailBodyContentTextAbout {
  padding: 24px !important;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  @media (max-width: 600px) {
    padding: 24px 12px !important;
  }
  br {
    display: block;
    height: 6px;
    content: ' ';
  }
}






#CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
  padding: 0 24px !important;
  @media (max-width: 600px) {
    padding: 0px 12px !important;
  }

  .CookieCard {

    padding: 24px 0 !important;
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
      display: flex;
      font-weight: 400 !important;
      line-height: 1em !important;
      // letter-spacing: 0.06em !important;
      font-family: 'Cheltenham' !important;
      text-transform: none !important;
      font-size: 21px !important;


      label {
        font-family: 'Cheltenham' !important;
        text-transform: none !important;
        font-size: 21px !important;
      }
      &:hover {
        color: $lightBlack !important;
      }
      &:before {
        display: none;
      }
      .CybotCookiebotDialogDetailBulkConsentCount {
        display: none;
      }
      &:after {
        display: block;
        margin-left: 6px;
        content: '+';
      }
    }
    .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
      padding: 24px 0 0 !important;
    }
  }
}

.CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 1.5em !important;
  letter-spacing: -0.01em;
}

.CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
  margin-left: 0 !important;
}