@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.tag {
  @include SerifText;
  font-size: 15px;
  line-height: 1em;
  background: $white;
  color: $black;
  border-radius: 6px;
}

body {
  &:global(.theme-green) {
    .tag {
      color: $white;
      background: $darkGreen;
    }
  }
}
