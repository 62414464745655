@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background: $white;

  transition: background 0.08s linear;
}

.navWrapperDesktop {
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 1201px) {
    padding-left: 56px;
    padding-right: 56px;
  }
}

.desktopNavItems {
  @media (min-width: 1201px) {
    margin-left: 24px !important;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    margin-left: 0px;
  }
}

.uspBar {
  display: flex;
  text-decoration: none;
  height: 36px;
  background: $lightBlack;
  color: $white;
  position: relative;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;

  @include breakpoint('mobile') {
    height: 32px;
  }

  p,
  a {
    @include SansSerifTextSmall;
    line-height: 1;
    font-size: 14px;
    padding-top: 1px;

    @include breakpoint('mobile') {
      font-size: 12px;
    }
  }

  a {
    @include CtaWithBorder;
    font-size: 14px;
    margin-bottom: 1px;
    @include breakpoint('mobile') {
      font-size: 14px;
    }
  }

  button {
    padding: 12px;
    position: absolute;
    right: 46px;

    @include breakpoint('mobile') {
      right: 0px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transform: translateY(120%);
  transition: transform 0.01s linear 0.31s, background 0.3s $easeOutCubic;
  will-change: transform;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  z-index: 20;
  &.active {
    transition: transform 0.01s linear, background 0.2s $easeOutCubic 0.02s;
    background: rgba(0, 0, 0, 0.2);
    transform: translateY(0%);
  }
}

.search {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  background: $white;
  border-top: 1px solid $borderGray;
  border-bottom: 1px solid $borderGray;
}

.header {
  border-bottom: 1px solid $borderGray;
}

.logoWrapper {
  width: 92px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transform: translateY(-1px);

  @include breakpoint('desktop') {
    transform: translateY(-2px);
    width: 105px;
  }
}

.button {
  position: relative;
  display: flex;
  cursor: pointer;
  @include SansSerifTextSmall;
  font-size: 13px;
  text-decoration: none;

  span {
    font-size: 13px;
  }

  @include breakpoint('desktop') {
    padding: 0px 14px 0px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }

  &.buttonAdjusted {
    bottom: -1px;
  }
}

.labelWrap {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $black;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  @include breakpoint('desktop') {
    a:hover &,
    button:hover & {
      &:after {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }
  }
}

.menuButton {
  div {
    width: 24px;
    height: 1px;
    background: $black;
    margin: 3px 0px;
    transition: transform 0.16s $easeInOutQuart, width 0.16s $easeInOutQuart;
  }

  &.active {
    div {
      &:first-child {
        transform: translateY(7px) rotate(-45deg);
      }
      &:nth-child(2) {
        width: 0px;
      }
      &:last-child {
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }
}

.close {
  position: relative;
  left: -4px;
  div {
    width: 24px;
    height: 1px;
    background: $black;
    margin: 3px 0px;
    transition: transform 0.16s $easeInOutQuart, width 0.16s $easeInOutQuart;
  }

  div {
    &:first-child {
      transform: translateY(7px) rotate(-45deg);
    }
    &:nth-child(2) {
      width: 0px;
    }
    &:last-child {
      transform: translateY(-7px) rotate(45deg);
    }
  }
}

.favCount,
.loggedIn {
  @include SansSerifTextSmall;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: $white;
  background: $black;
  font-size: 8px;
  top: 16px;
  right: 6px;
  @include breakpoint('desktop') {
    top: 22px;
    right: 6px;
  }
  border: 1px solid $white;
}

.navLink {
  display: flex;
  height: 100%;
  text-decoration: none;
  @include WeightRegular();
}

.subNavItem {
  position: absolute;
  left: 0;
  right: 0;
  background: $white;
  top: calc(100% + 1px);
  z-index: 20;
  transform: translateY(-300%);
  display: none !important; // we need to use important here since otherwise styles are overwritten by reflex. Used because we dont want any elements inside here tabbable for screen readers while they are not showing

  &.active {
    display: flex !important; // see above..
    transform: translateY(0);
  }

  a {
    @include SansSerifText;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .subLink {
    position: relative;
  }
}

.leftLinks {
  border-right: 1px solid $borderGray;
}

.navButton {
  @include SansSerifText;
  line-height: 1em;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  .chev {
    position: absolute;
    top: 45%;
    right: 0px;
    opacity: 0;
    transform: translateY(-45%);
    transition: opacity 0.065s linear, transform 0.12s ease-in-out;
  }

  &:hover {
    .chev {
      opacity: 1;
    }
  }

  &.activeButton {
    .chev {
      opacity: 1;
      transform: translateY(-30%) rotate(180deg);
    }
  }
}

.subLinkTitle {
  @include SerifText;
  font-size: 26px;
}

.highlightWrapper {
  width: 50%;
  padding-left: 32px;
  text-decoration: none;
  &:after,
  &:before {
    display: none !important;
  }
}

.highlight {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  color: $white;
  text-align: center;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 135%;
  }

  img {
    @include AbsoluteImage;
  }

  .highlightContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.2);
    @include breakpoint('desktop') {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include breakpoint('l') {
      padding-left: 12px;
      padding-right: 12px;
    }

    .title {
      @include SerifH4;
      font-size: 28px;
      white-space: break-spaces;
      text-align: left;
      letter-spacing: -0.02em;
    }
    .cta {
      @include SansSerifTextSmall;
      font-size: 15px;
      border-bottom: 1px solid $white;
    }
  }
}

.faqButton {
  margin-top: 2px;
}

.mobileSearchIcon {
  width: 20px;
  height: 20px;
  @include breakpoint('mobile') {
    width: 19px;
    height: 19px;
  }
}

.loggedIn {
  background: $green;
  width: 14px;
  height: 14px;
  right: 7px;
}
