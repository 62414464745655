@import 'src/styles/constants.scss';

.input {
  height: 56px;
  background: $borderGray;
  border: none;
  border-radius: 10px;
  color: $black;
  padding: 0 24px;
  width: 100%;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(57, 67, 56, 0.4);
    opacity: 1; /* Firefox */
  }
}
