@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.container {
}

.feedbackMessage {
}
.errorMessage {
  color: $red;
}

.newsletter {
  input {
    background: $black;
    font-family: $fontSansSerif;
    border-radius: 0;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $black inset !important;
      -webkit-text-fill-color: #fff;
    }
    @include breakpoint('mobile') {
      height: 40px;
    }
    padding: 0;
    color: $white;
    &::placeholder {
      color: $white;
    }
    &:focus {
      outline: none;
    }
  }
  font-family: $fontSerif;
  @include breakpoint('desktop') {
    min-height: 420px;
  }
  @include breakpoint('mobile') {
    min-height: 360px;
  }
  .consent {
    margin-top: 16px;
    span {
      font-family: $fontSerif;
      padding-top: 4px;
      font-size: 12px;
    }
    // label:before {
    //   background: $black;
    // }
    // :checked + label:before {
    //   background: $white;
    // }
  }
  .newsletterTitle {
    @include SerifH4;
    letter-spacing: normal;
    line-height: 1.3em;
    @include breakpoint('desktop') {
      font-size: 32px;
      line-height: 38px;
    }
    @include breakpoint('s') {
      width: 100%;
    }
  }

  .feedbackMessage {
    margin-top: 20px;
  }

  .errorMessage {
    color: $red;
  }
  .inputContainer {
    position: relative;
    border-bottom: 1px solid $borderGray;
  }
  .subscribeButton {
    width: fit-content;
    height: fit-content;
    padding-bottom: 13px;
    padding-right: 0;
    &:hover {
      background: inherit;
    }
    font-size: 32px;
    @include breakpoint('mobile') {
      padding-bottom: 8px;
      font-size: 24px;
    }
  }
}
