@import '../../styles/theme';

#aino-checkout .CountrySelectComponent {
  .wrapper {
    position: relative;
    @media all and (max-width: 500px) {
      text-align: right;
    }
    display: none;
  }

  .input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-top: 0;
    height: 20px;
    border: none;
    cursor: pointer;
    line-height: 1.1em;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 0;
    height: 100%;
    left: 0;

    @media (hover: none) and (pointer: coarse) {
      font-size: 16px; // so it doesnt zoom in on mobile
    }
  }

  .country {
    white-space: nowrap;
  }

  .icon {
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    margin-top: -2px;
    transform: scale(0.6, 0.6);
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
    margin-left: 2px;
  }
  .icon::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }

  .inputs {
    display: flex;
    align-items: flex-end;
    @media all and (max-width: $mobileBreakpoint) {
      align-items: center;
    }
    margin-top: 5px;
  }

  .colon {
    @media all and (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.675em;
    align-items: center;
    font-weight: 500;
    strong {
      color: #000;
      margin-left: 4px;
      font-weight: 500;
      @media all and (max-width: 500px) {
        display: block;
        margin: 0;
      }
    }
  }

  .pending {
    margin-top: 12px;
  }
}
