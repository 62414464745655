#aino-checkout .PaymentPickerComponent {
  display: flex;

  .paymentMethod {
    max-width: 120px;
    margin-right: 20px;
    opacity: 0.4;
    border: none;
    appearance: none;
    background: transparent;
    position: relative;
    padding-left: 21px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
      img {
        filter: none;
      }
    }
    img {
      max-width: 100%;
      max-height: 17px;
      filter: grayscale(100%);
    }
    &.pending {
      opacity: 1;
      img {
        opacity: 0.3;
      }
      .radio {
        border-color: transparent;
        i {
          background: #000;
        }
      }
    }
  }

  .active {
    opacity: 1;
    img {
      filter: none;
    }
  }

  .spinner {
    position: absolute;
    top: -1px;
    left: -1px;
  }

  .radio {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 1px;
    // left: 1em;
    left: 0;
    border-radius: 50%;
    border: 1px solid;
    font-size: 1em;
    display: block;
    transition: border-color 100ms ease-out;
    > i {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background:transparent;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: background-color 100ms ease-out;
      &.selectedRadio {
        background: #000;
      }
    }
  }
}