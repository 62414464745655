@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.popup {
  position: fixed;
  width: 100%;
  z-index: 11;
  background: #f4f4f4;
  border-radius: 16px;
  width: 368px;
  height: max-content;
  bottom: 32px;
  right: 32px;
  color: $black;
  padding: 32px 28px;

  @include breakpoint('mobile') {
    width: calc(100% - 40px);
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .feedbackMessage {
    @include SansSerifTextSmall;
    position: absolute;
    bottom: 9px;
    font-size: 12px;
  }

  h2 {
    @include SerifH3;
    font-size: 28px;
    line-height: 1em;

    @include breakpoint('mobile') {
      font-size: 26px;
    }
  }

  .newsletterText {
    @include SansSerifTextSmall;
    font-size: 16px;
    line-height: 1.5em;
  }

  .consent {
    font-family: $fontSerif;

    label {
      &:before {
        border-color: $lightBlack;
      }
    }
    span {
      font-size: 12px;
      line-height: 1em;
      padding-top: 2px;
    }
  }

  .inputContainer {
    position: relative;
    input {
      padding-top: 4px;
      font-size: 16px;
      line-height: 1em;
      background: transparent;
      border-bottom: 1px solid $black;
      border-radius: 0;
      width: 100%;
      outline: none;
      padding: 0;
      height: unset;
      padding-bottom: 9px;
      @include SansSerifText;

      &::placeholder {
        color: $lightBlack;
        opacity: 0.2;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
    }
  }

  .subscribeButton {
    @include SansSerifText;
    line-height: 1em;
    background: #f4f4f4;
    color: $lightBlack;
    position: absolute;
    height: 32px;
    width: fit-content;
    right: 0;
    bottom: 6px;
    padding: 0 0 0 10px;
  }

  .close {
    position: absolute;
    right: 28px;
    top: 34px;
  }
}
