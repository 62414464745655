@import '../theme';

#aino-checkout .CentraComponent {

  .inner {
    padding: 20px 0;
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 30px;
    padding-bottom: 15px;
    @media all and (max-width: $mobileBreakpoint) {
      display: block;
      .button {
        width: 100%;
      }
    }
  }
}