
@keyframes l1 {
  0% { color: transparent }
  24% { color: transparent }
  25% { color: inherit }
  49% { color: inherit }
  50% { color: inherit }
  74% { color: inherit }
  75% { color: inherit }
  100% { color: inherit }
}
@keyframes l2 {
  0% { color: transparent }
  24% { color: transparent }
  25% { color: transparent }
  49% { color: transparent }
  50% { color: inherit }
  74% { color: inherit }
  75% { color: inherit }
  100% { color: inherit }
}
@keyframes l3 {
  0% { color: transparent }
  24% { color: transparent }
  25% { color: transparent }
  49% { color: transparent }
  50% { color: transparent }
  74% { color: transparent }
  75% { color: inherit }
  100% { color: inherit }
}

#aino-checkout .LoaderComponent { 
  letter-spacing: 2px; 
  font-weight: bold;
  .l1 { animation: l1 700ms infinite }
  .l2 { animation: l2 700ms infinite }
  .l3 { animation: l3 700ms infinite }
}

#aino-checkout .SpinnerComponent {
  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 50px;
    height: 50px;
  }

  .spinnerCircle {
    stroke-linecap: round;
    animation: dash 1.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}