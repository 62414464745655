@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.button {
  position: relative;
  cursor: pointer;
  padding: 0 0 0 14px;
  span {
    font-size: 13px;
  }
}

.favCount {
  @include SansSerifTextSmall;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: $white;
  background: $black;
  font-size: 8px;
  top: 16px;
  right: -6px;
  @include breakpoint('desktop') {
    top: 22px;
    right: -6px;
  }
  border: 1px solid $white;
}
