#aino-checkout .IngridComponent {
  margin: 16px 0 0;
  position: relative;
  min-height: 105px;

  .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 0;
    padding-bottom: 30px;
    margin-top: 16px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 15px;
    background: #fff;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }
  }
}
