$blue: #1948f4;
$red: #f24667;
$yellow: #fffab8;
$green: #47a66c;

$secondaryColor: #1b6dd6;

$cartBackground: #ffffff;
$formsBackground: #eeeeed;
$upsaleBackground: #f4f3f2;

$formsMargin: 40px;
$formSectionPaddingX: 30px;
$formSectionPaddingY: 30px;
$formSectionBackground: #ffffff;
$formSectionMarginBottom: 20px;
$formSectionBorderBottomColor: rgba(0, 0, 0, 0.1);
$formSectionBorderRadius: 4px;

$formsBorderRightColor: transparent;

$helpBorderRightColor: rgba(0, 0, 0, 0.1);

$inputBorderRadius: 0;
$summaryBorderRadius: 8px;

$fontSize: 18px;
$h1FontSize: 1.4em;
$h2FontSize: 1.2em;
$h3FontSize: 1em;

$mobileBreakpoint: 1000px;
