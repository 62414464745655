@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.password {
  position: relative;

  .showHide {
    height: 56px;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0px;
    justify-content: flex-end;
    align-items: center;
    span {
      margin-right: 18px;
      font-size: 10px;
      font-family: $fontSansSerif;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
    }
  }

  .showPassword {
    &:focus,
    &:active {
      // padding-top: 7px;
    }
  }
}
