@use 'sass:math';

@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  max-width: $drawerMaxWidth;
  background: $white;
  color: $black;
  z-index: 200;
  transform: translateX(150%);

  @include breakpoint('mobile') {
    width: calc(100vw - 32px) !important;
  }
}

.closeBar {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
}

.closeIcon {
  position: relative;
  top: -2px;
  @include DefaultActive;

  svg {
    transform: rotate(45deg);
  }
}

.hero {
  h1 {
    @include SerifH1;
  }
  p {
    @include SansSerifText;
  }

  .inputWrapper {
    position: relative;

    .searchIcon {
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }

    .clear {
      position: absolute;
      right: 0px;
      padding: 0 14px;
      top: 0;
      height: 100%;
      svg {
        transform: rotate(45deg);
      }
    }
  }

  input {
    @include SerifText;
    height: 56px;
    background: $borderGray;
    border: none;
    border-radius: 10px;
    color: $black;
    padding: 4px 42px 0 44px;
    width: 100%;
    border: 1px solid $borderGray;
    background: $white;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(57, 67, 56, 1);
      opacity: 1; /* Firefox */
    }

    &:active,
    &:focus {
      outline: none;
      border-color: $black;
    }
  }
}

.title {
  @include SerifH4;
  @include breakpoint('mobile') {
    font-size: 22px;
  }
}

.subject {
  & > div {
    border-top: 1px solid $borderGray;
  }

  &:last-child {
    & > div {
      border-bottom: 1px solid $borderGray;
    }
  }
}

.question,
.answer {
  @include SansSerifText;

  mark {
    background: $black;
    color: $white;
  }
}

.question {
  @include WeightBold;
}
.answer {
  @include WeightRegular;
}

.questions {
  overflow: scroll;
  @include hideScrollbar;
}
