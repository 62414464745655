@import 'src/styles/constants.scss';

.hoverLink {
  a,
  button {
    text-decoration: none;
    font-variation-settings: 'wght' 370, 'GRAD' 88;
  }
  position: relative;
  white-space: nowrap;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $black;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.white {
    &:after {
      background-color: $white;
    }
  }

  &.isBrowser {
    &:hover {
      &:after {
        transform-origin: bottom left;
        transform: scaleX(1);
      }
    }
  }

  &.active {
    &:after {
      transform: scale(1);
      background-color: $black;
    }
  }
}
