@use 'sass:math';

@import '../../styles/theme';
@import 'src/styles/constants.scss';

#aino-checkout .MainScreen {
  margin: 0 auto;

  .layout {
    display: flex;
  }

  .logoHeader {
    padding: 0 0 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 700px;
    margin: 0 auto;
    .logo {
      height: 40px;
      max-width: 300px;
      justify-content: left;
      img,
      svg {
        max-height: 40px;
        max-width: 300px;
      }
      @media all and (max-width: $mobileBreakpoint) {
        max-width: 200px;
      }
    }
    @media all and (max-width: 500px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &.hide-desktop {
      display: none;
    }

    @media all and (max-width: $mobileBreakpoint) {
      align-items: center;
      margin-top: 0px;
      &.hide-desktop {
        display: flex;
        padding-top: 20px;

        @media all and (max-width: 720px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      &.hide-mobile {
        display: none;
      }
    }
  }

  .primary {
    flex: 1;
    display: flex;
  }

  .cart {
    min-height: 100vh;
    order: 2;
    background: $cartBackground;
    position: sticky;
    top: 0;
    margin: 0 auto;

    @media all and (max-width: $mobileBreakpoint) {
      order: 0;
      position: relative;
      min-height: 0;
      max-height: 48px;
      max-width: 700px;
      margin: 0 auto;
      overflow: hidden;
      border-radius: $formSectionBorderRadius;
      transition: max-height 0.4s cubic-bezier(0.7, 0, 0, 0.7);
      width: calc(100% - $formsMargin);
      &.cartOpen {
        margin-bottom: $formSectionMarginBottom;
        max-height: 4000px;
      }
    }
  }

  .innerCart {
    min-height: 100vh;
    overflow: auto;
    min-width: 39vw;
    margin: 0 auto;
    @media all and (max-width: $mobileBreakpoint) {
      min-height: 0;
      padding: 15px;
    }
  }

  .cartContent {
    // width: calc(27% - 40px);
    max-width: $drawerMaxWidth;
    padding: 0 40px 40px;
    @media all and (max-width: $mobileBreakpoint) {
      padding: 0;
      max-width: 100%;
    }
  }


  .forms {
    min-height: 100vh;
    order: 1;
    // padding: 0 40px;
    width: 60vw;
    //max-width: 900px;
    background: $formsBackground;
    border-right: 1px solid $formsBorderRightColor;
    @media all and (max-width: $mobileBreakpoint) {
      width: 100%;
      padding: 0 ($formsMargin * 0.5) 20px;
    }
    display: flex;
    justify-content: flex-end;
  }

  .formsInner {
    width: 100%;
    max-width: 700px + ($formsMargin * 2);
    padding: 40px $formsMargin;
    @media all and (max-width: $mobileBreakpoint) {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      margin: 0 auto;
    }
  }

  .primaryInner {
    display: flex;
    margin: 0 auto;
    width: 100%;
    align-items: flex-start;
    @media all and (max-width: $mobileBreakpoint) {
      flex-direction: column;
    }
  }

  .header {
    height: 3em;
    border-bottom: 1px solid rgba(#000, 0.1);
    display: flex;
    align-items: center;
    margin-top: 40px;
    @media all and (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .editLink {
    font-size: 0.875em;
    color: rgba(#000, 0.5);
    position: absolute;
    right: 0;
    font-weight: normal;
  }

  .bagTitle {
    position: relative;
    display: block;
    width: 100%;
    @media all and (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .formSection {
    border-radius: $formSectionBorderRadius;
    @media all and (max-width: 500px) {
      border-radius: 0;
    }
    margin: 0 auto $formSectionMarginBottom;
    max-width: 640px + ($formSectionPaddingX * 2);
    border-bottom: 1px solid $formSectionBorderBottomColor;
    opacity: 0.5;
    transition: opacity 200ms ease-out, background-color 200ms ease-out;
    &.active {
      opacity: 1;
      background: $formSectionBackground;
    }
  }

  .formHeader {
    h2 {
      padding: ($formSectionPaddingY * 0.75) 0;
      margin: 0 $formSectionPaddingX;
      @media all and (max-width: $mobileBreakpoint) {
        margin: 0 ($formSectionPaddingX * 0.5);
      }
    }
  }

  .active .formHeader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .shippingHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $formSectionPaddingX;
  }

  .number {
    display: inline-block;
    border: 1px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 2.5px;
    font-weight: 500;
    margin-right: 6px;
  }

  .formContent {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 $formSectionPaddingX;

    @media all and (max-width: $mobileBreakpoint) {
      padding: 0 math.div($formSectionPaddingX, 1.5);
    }
  }

  .h2Button {
    appearance: none;
    font-size: inherit;
    border: none;
    padding: 0;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    position: relative;
    background: transparent;
    font-size: 16px;
  }

  span.edit {
    position: absolute;
    right: 30px;
    font-size: 0.75em;
    opacity: 0.4;
    top: 30px;
    @media all and (max-width: 500px) {
      right: 20px;
    }
    svg {
      display: inline-block;
      width: 12px;
      margin-right: 6px;
    }
  }

  .toggleCart {
    width: auto;
    padding: 0; //12px 20px;
    display: none;
    background: $cartBackground;

    @media all and (max-width: $mobileBreakpoint) {
      display: block;
    }

    &.active {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .toggleButton {
    background: $formsBackground;
    color: black;
    text-align: center;
    height: 48px;
    font-size: 14px;
    width: 100%;
    background: $secondaryColor;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 2px;
    @media all and (max-width: $mobileBreakpoint) {
      border-radius: 0;
    }

    &:active,
    &:focus {
      outline: none;
    }

    padding: 0 15px;
    display: flex;
    justify-content: space-between;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      position: relative;
      transition: transform 0.4s cubic-bezier(0.7, 0, 0, 0.7);
      path {
        fill: white;
      }
    }
  }

  .debugButtons {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10;
  }

  > .error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    height: 100%;
    width: 100%;
    background: rgba(#000, 0.2);

    .errorBox {
      max-width: 400px;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;
      margin: 0 20px;
      background: #fff;
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 8px;
      text-align: center;
      position: relative;
      .errorClose {
        color: #000;
      }
      h2 {
        color: $red;
        margin-bottom: 10px;
      }
      .ButtonComponent {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }

  .errorClose {
    border: none;
    padding: 0 0 8px;
    margin: 0;
    background: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    font-size: 20px;
    appearance: none;
    height: 40px;
    width: 40px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .return {
    font-size: 0.675em;
    font-weight: 500;
    position: relative;
    line-height: 1;
    white-space: nowrap;
    @media all and (max-width: $mobileBreakpoint) {
      white-space: normal;
    }

    svg {
      width: 10px;
      height: 7px;
      margin-left: 6px;
    }

    path {
      stroke: $secondaryColor;
    }

    button {
      color: $secondaryColor;
      text-align: right;
      border: none;
      padding: 0;
      white-space: nowrap;
      appearance: none;
      background: none;
      cursor: pointer;
      &:hover {
        opacity: 1;
        color: inherit;
      }
      @media all and (max-width: $mobileBreakpoint) {
        font-size: 10px;
      }
    }
  }

  .shippingPending {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(#fff, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .paymentPicker {
    border: 1px solid rgba(#000, 0.06);
    border-radius: 4px;
    @media all and (max-width: 500px) {
      border-radius: 0;
    }
    padding: 25px 30px;
    max-width: 700px;
    margin: 0 auto;
    @media all and (max-width: $mobileBreakpoint) {
      border: 0;
      border-top: 1px solid rgba(#000, 0.06);
      padding: 20px 15px 0;
    }
    margin-bottom: 20px;
    display: flex;
    > span {
      font-size: 0.75em;
      position: relative;
      top: -2px;
      display: block;
      margin-right: 20px;
    }
    .logo {
      display: flex;
    }
  }
}
