@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  position: relative;
  z-index: 10;
  // top: -1px;
  background: $black;
  color: $white;
}

.copyright {
  @include SerifH4;
  justify-content: flex-start;
  letter-spacing: normal;
  font-size: 16px;
  position: absolute;
  @include breakpoint('mobile') {
    left: 24px;
    bottom: 34px;
  }
  @include breakpoint('desktop') {
    bottom: 61px;
    right: 57px;
    justify-content: flex-end;
    font-size: 20px;
  }
}

.navigation {
  justify-content: flex-end;
  a {
    // @include InvertedCtaWithBorder;
    // &:before,
    // &:after {
    //   background: $white;
    // }
    font-family: $fontSansSerif;
    text-decoration: none;
    line-height: 24px;
  }
}

body {
  &:global(.theme-green) {
    .wrapper {
      color: $darkGreen;
    }
  }
}
