@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.product {
  position: relative;

  a {
    text-decoration: none;
  }

  .imageWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: $borderGray;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }
    img {
      @include AbsoluteImage;
    }
    .buttons {
      position: absolute;
      z-index: 3;
      right: 0;
      bottom: 0;

      button {
        width: 26px;
        height: 26px;
        border-radius: 4px;

        &:hover {
          background: $mediumGray;
        }
        svg {
          width: 12px;
        }
      }
    }
  }

  .price,
  .name,
  .variantName {
    @include SansSerifText;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    @include breakpoint('mobile') {
      font-size: 12px;
    }
  }

  .variantName {
    color: $lightBlack;
    opacity: 0.3;
  }

  .name {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .button {
    @include SansSerifTextSmall;
    border: 1px solid $black;
    border-radius: 8px;
    padding: 0 12px;
  }
}
