@import "./theme";

body.prevent-overflow {
  overflow-y: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @supports (-webkit-overflow-scrolling: touch) {
    position: fixed;
  }
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#aino-checkout {
  background: $formsBackground;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: none;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: $fontSize;
  line-height: 1.4;
  * {
    box-sizing: border-box;
  }

  input,
  button,
  code {
    font-family: inherit;
    font-size: inherit;
  }
  #root {
    min-height: 100%;
  }

  svg {
    display: inline-block;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $blue;
    }
  }

  p a,
  li a {
    color: $secondaryColor;

    &:hover {
      color: inherit;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }

  h1 {
    font-size: $h1FontSize; // 1.4em;
  }

  h2 {
    font-size: $h2FontSize; // 1em;
  }

  h3 {
    font-size: $h3FontSize; // 0.875em;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  }
}
