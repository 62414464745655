@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

$ctaWrapperHeightDesktop: 235px; //90px;
$ctaWrapperHeightMobile: 185px; //80px;

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: $drawerMaxWidth;
  background: $white;
  color: $black;
  z-index: 200;

  @include breakpoint('mobile') {
    width: calc(100vw - 32px) !important;
  }
}

.buttonSpinner {
  position: relative;
  top: 1px;
}

.closeIcon {
  position: relative;
  top: -2px;
  @include DefaultActive;

  svg {
    transform: rotate(45deg);
  }
}

.title,
.emptyCart,
.upsaleTitle {
  @include SerifH4;
  @include breakpoint('mobile') {
    font-size: 22px;
  }
}

.upsaleTitle {
  font-size: 18px;
  @include breakpoint('mobile') {
    font-size: 16px;
  }
}

.emptyCart {
  width: 100%;
  // white-space: nowrap;
}

.itemWrapper {
  max-height: 158px;
  @include breakpoint('mobile') {
    max-height: 126px;
  }
}

.item {
  display: flex;
  width: 100%;
  flex-shrink: 0;

  &.upsaleWrapper {
    &:not(:has(.upsaleItem)) {
      display: none;
    }
  }
}

.product {
  position: relative;
  border-bottom: 1px solid $borderGray;

  .imageWrapper {
    flex-shrink: 0;
    width: 88px;
    background: $borderGray;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 133%;
    }
    img {
      @include AbsoluteImage;
    }

    @include breakpoint('mobile') {
      width: 64px;
    }
  }

  .price {
    padding-top: 2px; // to get level line-height with name and variant name
  }

  .price,
  .variantName,
  .name {
    @include SansSerifText;
    font-size: 14px;
    @include breakpoint('mobile') {
      font-size: 12px;
    }
  }

  .old {
    text-decoration: line-through;
    color: rgba(17, 17, 17, 0.2);
  }

  .variantName {
    @include SerifText;
    font-size: 14px;
    @include breakpoint('mobile') {
      font-size: 13px;
    }
  }

  .remove {
    display: none;
    @include SansSerifText;
    font-size: 15px;
    color: rgba(17, 17, 17, 0.2);
    padding-right: 0;

    &:hover {
      color: $black;
    }
  }

  .plus,
  .minus,
  .quantity {
    position: relative;
    @include SansSerifTextSmall;
    height: 20px;
    font-size: 13px;
  }
  @keyframes pendingAnimation {
    0% {
      stroke-dashoffset: 125;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  .plus {
    &.inactive {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .spinner {
    position: absolute;
    top: -3px;
    circle {
      stroke-dashoffset: 125;
    }
  }
  .pending {
    .spinner {
      circle {
        animation-name: pendingAnimation;
        animation-duration: 0.75s;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.19, 1, 0.82, 1);
      }
    }
  }
  .quantityWrapper {
    align-items: flex-start;
    border: none;
    position: relative;
  }

  .plus,
  .minus {
    width: 20px;
    border-radius: 50%;
    border: 1px solid $borderGray;
    @include breakpoint('desktop') {
      &:hover {
        background: $darkGray;
      }
    }
  }
  .quantity {
    padding: 0 10px;
  }
}

.products {
  @include hideScrollbar;
  overflow: scroll;
  flex-shrink: 0;

  height: calc(100vh - $ctaWrapperHeightDesktop);

  @include breakpoint('mobile') {
    height: calc(100vh - $ctaWrapperHeightMobile);
  }

  @include breakpoint('mobile') {
    padding-bottom: 90px; // for ios toolbar
  }

  &.noProducts {
    height: calc(100vh - 215px);
    @include breakpoint('mobile') {
      height: calc(100vh - 185px + 90px);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  transform: translateY(120%);
  transition: transform 0.1s linear 0.3s, background 0.3s $easeOutCubic;
  will-change: transform;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  z-index: 150;
  &.active {
    transition: transform 0.1s linear, background 0.3s $easeOutCubic 0.1s;
    background: rgba(0, 0, 0, 0.2);
    transform: translateY(0%);
  }
}

.freightButton {
  @include SerifText;
  padding-top: 3px;
  font-size: 14px;
  background: $mediumGray;
  height: 40px;
  border-radius: 2px;
  width: 100%;

  @include breakpoint('mobile') {
    height: 36px;
  }

  svg {
    position: relative;
    top: -2px;
  }
}

.totals {
  span {
    @include SansSerifText;
    font-size: 15px;
    @include breakpoint('mobile') {
      font-size: 12px;
    }
  }

  .summary {
    .total {
      // font-weight: bold;
    }
  }

  > div:last-child {
    margin-bottom: 0;
  }
}

.upsale {
  // border: 1px solid $lightGray;
  // background: $lightGray;

  border-radius: 8px;
  flex-shrink: 1;
  overflow: scroll;
  @include hideScrollbar;
}

.sale {
}

.ctaWrapper {
  position: fixed;
  bottom: 0;
  z-index: 999999999999999;
  width: 100%;
  right: 0;
  background: $white;
  border-top: 1px solid $borderGray;
  @include breakpoint('desktop') {
    padding-bottom: 30px;
  }
  @include breakpoint('mobile') {
    height: fit-content;
    padding-bottom: 20px;
  }
}

.cta {
  font-size: 22px;

  @include breakpoint('mobile') {
    height: 48px;
    font-size: 18px;
  }
}
