@import './fonts.scss';
@import './mixins.scss';
@import './constants.scss';
@import './lipscoreDefault.scss';
@import './cookieBot.scss';


html {
  font-family: 'ABC Diatype';
  font-feature-settings: 'ss05' on;
  margin: 0;
  min-height: 100%;
  color: $black;

  // ::-webkit-scrollbar {
  //   display: none;
  //   width: 0px;
  //   background: transparent; /* make scrollbar transparent */
  // }

  // -ms-overflow-style: none; /* IE 11 */
  // scrollbar-width: none; /* Firefox 64 */
}

#site-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  margin: 0;
  font-weight: 400;
  // padding-top: 110px;
  @include breakpoint('mobile') {
    padding-top: $topMobileWithUsp;
    &.no-uspbar {
      padding-top: $topMobile;
    }
  }
  @include breakpoint('desktop') {
    padding-top: $topDesktopWithUsp;
    &.no-uspbar {
      padding-top: $topDesktop;
    }
  }

  &.no-scroll {
    overflow: hidden;
  }

  #aino-content {
    // min-height: 600px;
    min-height: 85vh;
    display: flex; // so child items can be 100% of available height
    flex-direction: column;

    > * {
      flex: 1;
    }
  }

  &.thanks {
    #aino-content {
      margin-top: auto;
    }
  }

  &.checkout {
    padding-top: 0 !important;
  }
}

html,
body {
  ::selection {
    background-color: #000;
    color: #fff;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

figure {
  margin: 0;
}

strong,
b {
  @include WeightBold;
}

img {
  max-width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
a,
span {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 100%;
  line-height: 1em;
  cursor: default;
}

a,
button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input {
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
}

button {
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  line-height: inherit;
  padding: 0;
  margin: 0;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: center;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  outline: none;
}

a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

a[href^='tel:'] {
  color: inherit;
}

p + p {
}

h1,
h2,
h3,
h4 {
}

.site {
  min-height: 100vh;
  background: #fff;
  max-width: 100vw;
  overflow: hidden;
}

.hideMobile {
  @include breakpoint('mobile') {
    display: none !important;
  }
}

.hideDesktop {
  @include breakpoint('desktop') {
    display: none !important;
  }
}

.aino-image {
  display: block;
  opacity: 0;

  transition: opacity 150ms linear;
}

// Lipscore

.lipscore-compact-rating-wrapper {
  display: none !important;
}

.lipscore-rating-review-link {
  display: none;
}

.lipscore-rating-slider-stars-wrapper {
  pointer-events: none;
}

.lipscore-rating-slider,
.lipscore-rating-slider-readonly {
  // cursor: pointer !important;
}

.lipscore-rating-star-wrapper {
  svg {
    width: 19px;
    height: 19px;
    margin-left: 3px;
  }
}

.lipscore-review-media-box-wrapper {
  display: none;
}

.lipscore-reviews-modal-items {
  background: #fff;
  border-radius: 20px;
}

.lipscore-review-avatar {
  display: none;
}

.lipscore-reviews-modal-thank {
  color: unset;
}

.lipscore-reviews-modal {
  * {
    font-family: unset !important;
    box-shadow: unset !important;
  }
}

.lipscore-product-row:last-of-type {
  border-bottom: unset;
}

.lipscore-reviews-products-wrapper {
  background: unset !important;
}

.lipscore-btn-dark {
  background: #000;
  color: #fff;
}

.lipscore-circle-loader {
  display: none;
}

.lipscore-reviews-modal-rating-slider {
  .lipscore-rating-slider-stars-wrapper {
    pointer-events: unset;
  }
  .lipscore-rating-star-wrapper {
    svg {
      width: unset;
      height: unset;
    }
  }
}

.lipscore-review-content-wrapper {
  background: #fff;
}
