@import '../../styles/theme';

#aino-checkout .InputComponent {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.825em;
  #app.box-input & {
    margin-bottom: 1.2em;
    input,
    select {
      height: 2.6em;
      border: 1px solid rgba(#000, 0.25);
      border-radius: $inputBorderRadius;
      padding-left: 0.5em;
      padding-right: 0.5em;
      padding-bottom: 1px;
      &.hasValue {
        padding-top: 0.2em;
      }
      &:focus {
        box-shadow: rgba(#000, 0.05) 0 0 0 2px;
        border-color: rgba(#000, 0.5);
      }
      &.softError {
        border-color: rgba($red, 0.7);
        //box-shadow: rgba($red, 0.05) 0 0 0 2px;
      }
      &.error {
        border-color: rgba($red, 0.7);
        background: rgba($red, 0.04);
        box-shadow: rgba($red, 0.1) 0 0 0 2px;
        &:focus {
          border-color: rgba($red, 1);
          box-shadow: rgba($red, 0.1) 0 0 0 2px;
        }
        &::placeholder {
          color: rgba(desaturate($red, 30%), 0.5);
        }
      }
    }
    > span.label {
      background: #fff;
      left: calc(0.5em - 2px);
      top: -7px;
      padding: 0 6px;
    }
  }
  .input {
    width: 100%;
    font-size: 1em;
    border: none;
    border-bottom: 1px solid rgba(#000, 0.2);
    padding: 0;
    height: 2.75em;
    transition: border 50ms ease-out;
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    font-weight: 500;
    line-height: 1;
    &:focus {
      outline: none;
      border-color: #000;
    }
    &::placeholder {
      color: rgba(#000, 0.4);
      font-size: 0.875em;
      font-weight: 400;
    }
    &.hasValue {
      padding-top: 0.9em;
    }
  }
  select.input {
    padding-top: 0.625em;
  }

  .errorMessage {
    color: $red;
    font-size: 0.65em;
    margin: 0.2em 0;
    font-weight: 500;
  }
  .helpText {
    font-size: 0.65em;
    margin: 0.2em 0;
    font-weight: 500;
    opacity: 0.5;
  }
  .softError {
    border-color: rgba($red, 0.7);
    &::placeholder {
      color: rgba(desaturate($red, 20%), 0.5);
    }
  }

  .error {
    border-color: rgba($red, 0.7);
    background: rgba($red, 0.04);
    &:focus {
      border-color: rgba($red, 1);
    }
    &::placeholder {
      color: rgba(desaturate($red, 30%), 0.3);
    }
  }

  .label {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    font-size: 0.6875em;
    top: 0;
    left: 0.5px;
    display: inline-block;
    color: transparent;
    padding: 0;
    transition: color 100ms ease-out;
  }

  .showLabel {
    opacity: 1;
    color: rgba(#000, 0.4);
  }

  .focusLabel {
    color: #000;
  }

  .errorLabel {
    color: rgba(desaturate($red, 20%), 0.5);
  }

  .input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-top: 0.375em;
  }

  // SELECT

  .select_triangle {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: absolute;
    right: 1px;
    top: 20px; //0.875em;
    display: block;
    pointer-events: none; // so we click through it to the <select> underneath
    background: rgba(#000, 0.3);
    i {
      width: 100%;
      height: 100%;
      background: #fff;
      margin-top: -1.6px;
      margin-left: -1.6px;
      display: block;
    }
    #app.box-input & {
      right: 14px;
      margin-top: 2px;
    }
  }

  .select_triangleFocus {
    background: #000;
  }

  .select_triangleError {
    background: rgba(desaturate($red, 20%), 0.5) !important;
  }

  .select_focus {
    background: #000;
  }

  .select_placeholder {
    color: rgba(#000, 0.4);
    font-size: 0.875em;
    font-weight: 400;
    height: 3.15em;
    padding-bottom: 0.2em;
  }

  .select_placeholderSoftError {
    color: rgba(desaturate($red, 20%), 0.5);
  }

  .select_placeholderError {
    color: rgba(desaturate($red, 20%), 0.3);
  }
}
