@font-face {
  font-family: 'ABC Diatype';
  src: url('/fonts/ABCDiatypeVariable.woff2') format('woff2'),
    url('/fonts/ABCDiatypeVariable.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cheltenham';
  src: url('/fonts/ITCCheltenhamProLight.woff2') format('woff2'),
    url('/fonts/ITCCheltenhamProLight.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cheltenham';
  src: url('/fonts/ITCCheltenhamProLightItalic.woff2') format('woff2'),
    url('/fonts/ITCCheltenhamProLightItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
