@import '../../styles/theme';

#aino-checkout .CheckboxComponent {
  .wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      &:checked ~ .checkBox {
        border-color: rgba(#000, 0.8);
        background: rgba(#000, 0.8);
        &:after {
          display: block;
        }
      }
      &:focus ~ .checkBox {
        border-color: #000;
        box-shadow: rgba(#000, 0.1) 0 0 0 2px;
      }
    }
  }

  .checked .label {
    color: #000;
  }

  .label {
    font-size: 0.65em;
    margin-left: 23px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    color: rgba(#000, 0.5);
    font-weight: 500;
    button {
      display: contents;
      text-align: left;
      word-wrap: break-word;
      padding: 0;
      border: none;
      appearance: none;
      background: none;
      cursor: pointer;
      color: inherit;
      font-size: inherit;

      &:hover {
        color: $secondaryColor;
        border-color: rgba($secondaryColor, 0.5);
      }

      > span {
        text-decoration: underline;
      }
    }
    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: $secondaryColor;
      }
    }
  }

  .errorLabel {
    color: $red;
  }

  .errorMessage {
    color: $red;
    font-size: 0.65em;
    margin: 0.2em 0;
    font-weight: 500;
    margin-left: 22px;
  }

  .checkBox {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid rgba(#000, 0.3);
    background: rgba(#000, 0.02);
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
    svg {
      left: 3px;
      width: 7px;
      position: absolute;
      top: 4px;
      polyline {
        stroke-width: 1.5px;
        stroke: #fff;
      }
    }
  }

  .errorCheckBox {
    border-color: rgba($red, 0.7);
    background: rgba($red, 0.04);
  }
}
