@use 'sass:math';

@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  max-width: $drawerMaxWidth;
  background: $white;
  color: $black;
  z-index: 200;
  transform: translateX(150%);

  @include breakpoint('mobile') {
    width: calc(100vw - 32px) !important;
  }
}

.closeBar {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
}

.closeIcon {
  position: relative;
  top: -2px;

  @include DefaultActive;
  
  svg {
    transform: rotate(45deg);
  }

  @include breakpoint('mobile') {
    svg {
      width: 16px;
    }
  }
}

.title {
  @include SerifH4;
  @include breakpoint('mobile') {
    font-size: 22px;
  }
}

.links {
  p {
    @include SerifText;
    font-size: 16px;
    line-height: 1.3em;
  }
  .link {
    @include CtaWithBorder;
    @include SerifText;
    line-height: 1.3em;
    &:after,
    &:before {
      position: absolute;
      background-color: $black;
      bottom: 2px;
    }
    text-decoration: none;
    font-size: 16px;
  }
}
