@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

#aino-checkout .MainScreen .return {
  button {
    color: $black;
  }
  svg {
    path {
      stroke: $black;
    }
  }
}

#aino-checkout .GiftCardComponent .show:hover,
#aino-checkout .CheckboxComponent .label button:hover,
#aino-checkout .MainScreen .return button {
  color: $black;
}

#aino-checkout .GiftCardComponent .button {
  color: #fff !important;
  cursor: pointer;
}

#aino-checkout .GiftCardComponent .softError {
  background: transparent;
}

.emptyCart {
  h1 {
    font-family: 'Cheltenham';
    font-weight: 300;
    font-size: 48px;
    line-height: 57.6px;
    letter-spacing: -2%;
    white-space: nowrap;
    @include breakpoint('mobile') {
      font-size: 36px;
      margin: 12px 0;
    }
  }
}

/*
#aino-checkout .TotalsComponent .removeVoucher {
  // color: #eee;
  position: relative;
  display: inline-block;
  // background: #eee;
  // width: 16px;
  // height: 16px;
  // border-radius: 50%;
  white-space: nowrap;
  color: #fff;
  span {
    color: #fff;
    position: relative;
    &:before {
      color: #aaa;
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      content: '(remove)';
      cursor: pointer;
    }
  }
}

*/

#aino-checkout .TotalsComponent .removeVoucher {
  color: #999;
  text-transform: lowercase;
  cursor: pointer;
  &:hover {
    color: #000;
  }
  span {
    cursor: pointer;
  }
}

#aino-checkout .MainScreen .formSection {
  border-bottom: none;
  border-radius: 8px;
}

#aino-checkout .ButtonComponent button {
  border-radius: 8px !important;
  background: $black !important;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

#aino-checkout .CheckboxComponent .checked .label {
  color: $black;
}

#aino-checkout .MainScreen .number {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  position: relative;
  margin-right: 12px;
  position: relative;
  top: -0.5px;
  display: none !important;
}

#aino-checkout .UpsaleComponent {
  border-top: none;
}

#aino-checkout .SummaryComponent dt,
#aino-checkout .ProductsComponent .productTotal,
#aino-checkout .CheckboxComponent .label,
#aino-checkout .InputComponent .input {
  font-weight: 400;
  // @include WeightBold;
}
#aino-checkout .SummaryComponent dt {
  font-weight: 400;
  @include WeightBold;
}

#aino-checkout .MainScreen .formHeader {
  h2 {
    align-items: center;
    display: flex !important;
    margin-left: 30px;
    @media screen and (max-width: 1024px) {
      margin-left: 20px;
    }
  }
}

#aino-checkout .UpsaleComponent .title,
#aino-checkout .UpsaleComponent .price {
  text-align: left;
}

#aino-checkout .ChoiceComponent .radio {
  top: calc(1em + 0px);
}

#aino-checkout .ChoiceComponent .inner {
  padding: 1em 1em 0.75em 24px;
}
.adyen-checkout__button {
  background: $black !important;
}

.adyen-checkout__payment-method__header {
  color: $black !important;
}

.adyen-checkout__payment-method__radio--selected {
  background: $black !important;
}

.adyen-checkout__payment-method__radio--selected:hover {
  box-shadow: 0 0 0 2px rgb(32 32 32 / 20%) !important;
}

#aino-checkout {
  .samplesHeader {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    button {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      &:hover {
        color: inherit;
      }
    }
  }
  .samples-select {
    padding: 20px 0;
    font-size: 16px;
    .react-select__control {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      &:hover {
        border-color: rgba(0, 0, 0, 0.3);
      }
      padding: 2px 0;
    }
    .react-select__control--is-focused {
      border-color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .react-select__option {
      cursor: pointer;
    }
    .samples-select-active {
      font-size: 0.72em;
      display: inline-block;
      color: rgba($black, 0.5);
      padding-bottom: 0.5em;
    }
    .samples-select-label {
      font-size: 16px;
      span {
        position: relative;
        top: 1px;
      }
      .samples-select-label-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .samples {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    button {
      display: flex;
      align-items: center;
      width: 50%;
      opacity: 0.5;
      cursor: pointer !important;
      &.active {
        opacity: 1;
        .image {
          border-color: rgba(0, 0, 0, 0.8);
        }
      }
      &:hover {
        opacity: 1;
      }
      p {
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        width: calc(100% - 100px);
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
      .image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #fff;
        &.nothing {
          > div {
            background: #e4e4e4;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 40px;
          }
        }
        > div {
          margin: 2px;
          overflow: hidden;
          border-radius: 50%;
          width: 74px;
          height: 74px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
