@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  .button {
    @include SerifH4;
    font-size: 26px;
    height: 104px;
    flex-shrink: 0;

    @include breakpoint('mobile') {
      height: 72px;
      font-size: 20px;
      padding-top: 4px;
    }

    svg {
      transition: transform 0.12s ease-in-out;
      @include breakpoint('mobile') {
        width: 14px;
        height: 14px;
      }
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .questions {
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $borderGray;
    }

    & > div {
      &:first-child {
        padding-top: 32px;
      }
    }
  }

  .question,
  .answer {
    @include SansSerifText;
    p {
      @include SansSerifText;
    }
  }
  .question {
    @include WeightBold;
  }
  .answer {
    @include WeightRegular;
  }

  &.small {
    .button {
      font-size: 20px;
      height: 64px;
      padding: 0;
      @include breakpoint('desktop') {
        height: 60px;
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }
    .questions {
      & > div {
        padding: 0;
        &:first-child {
          padding-top: 24px;
        }
      }
    }
  }
}
