@import '../../styles/theme';

#aino-checkout .AddressComponent {
  transition: height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;

  .inner {
    position: relative;
    padding: 20px 0;
  }

  .smallRow {
    display: flex;
    justify-content: space-between;

    label {
      width: 48.4%;
    }

    @media all and (max-width: 1000px) {
      flex-direction: column;

      label {
        width: 100%;
      }
    }
  }

  .latin-error {
    background: rgba($red, 0.1);
    color: $red;
    padding: 10px;
    font-size: 0.75em;
    text-align: center;
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    padding-bottom: 15px;
    @media all and (max-width: $mobileBreakpoint) {
      display: block;
      .button {
        width: 100%;
      }
    }
  }

  .shipping {
    border-bottom: 1px solid rgba(#000, 0.2);
    display: flex;
    align-items: center;
    p {
      font-size: 0.75em;
      line-height: 1.4;
    }
    padding: 1em 0;
    margin-bottom: 20px;
  }

  .shippingLogo {
    margin-right: 1em;
    img {
      max-height: 38px;
      width: auto;
    }
  }

  .shippingInfo {
    margin-top: -5px;
  }

  .checkboxes {
    position: relative;
    top: -2px;
    left: 2px;
    padding-right: 12px;
    @media all and (max-width: $mobileBreakpoint) {
      width: 100%;
      margin-bottom: 12px;
      padding-right: 0;
    }
  }

  .showAddress2 {
    border: none;
    position: absolute;
    right: 0;
    margin-top: 20px;
    padding: 0;
    appearance: none;
    opacity: 0.5;
    cursor: pointer;
    background: none;
    &:hover {
      opacity: 1;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
