@import '../../styles/theme';

#aino-checkout .ProductsComponent {
  margin-bottom: 1em;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  .product {
    border-bottom: 1px solid rgba(#000, 0.1);
    padding: 10px 0;
    background: transparent;
  }

  .productContent {
    min-height: 50px;
    // margin: 0.5em 0;
    // background: $cartBackground;
    img {
      mix-blend-mode: multiply;
      max-width: 80px;
      height: auto;
      max-height: 80px;
      display: block;
      border-radius: 4px;
    }
    position: relative;
  }

  .productInfo {
    display: flex;
    position: absolute;
    left: calc(80px + 1em);
    top: 0;
    height: calc(100% - 10px);
    justify-content: space-between;
    align-items: center;
    font-size: 0.66667em;
    width: calc(100% - 80px - 1em);
  }

  .productInfoInner {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .productTitle {
    font-weight: normal;
    line-height: 1.4;
    font-size: 1em;
    padding-right: 5px;
    & > span {

      display: block;
      &:last-child {
        line-height: 1.4em;
      }
      &:first-child{
        line-height: 1em;
      }
    }
  }

  .size {
    display: block;
    opacity: 0.5;
  }

  .price {
    text-align: right;
    line-height: 1.4;
    flex: 1;
    transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: 100% 50%;
    white-space: nowrap;
  }

  .multiQuantity {
    display: block;
  }

  .productTotal {
    font-weight: bold;
  }

  .highlight .price {
    // box-shadow: inset 0 100px 0 yellow;
    transform: scale(1.2, 1.2);
  }

  .highlightTick .price {
    transform: scale(1, 1);
    transition-duration: 1s;
  }

  // buttons

  .buttons {
    display: flex;
    width: 60px;
    margin-left: auto;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .button {
    border: none;
    border-radius: 50%;
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    width: 16px;
    height: 16px;
    position: relative;
    border: 1px solid rgba(#000, 0.1);
    background: none;
    cursor: pointer;
    transition: border-color 0.08s linear;
    &.pending {
      border-color: transparent;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      border-color: rgba(#000, 1);
      &.pending {
        border-color: transparent;
      }
    }

    &:last-child {
      margin-left: 6px;
    }

    &:focus,
    &:active {
      outline: none;
    }
    &:active {
      transform: scale(0.94);
    }
  }
}
