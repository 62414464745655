@import '../../styles/theme';

#aino-checkout .SummaryComponent {
  font-size: 0.75em;
  cursor: pointer;
  transition: border-color 100ms ease-out;
  &:hover {
    border-color: rgba(#000, 0.1);
    .editButton {
      opacity: 1;
    }
  }
  dd { 
    margin: 0;
    padding-right: 2em;
    line-height: 1.5;
  }
  dt {
    font-weight: bold;
  }
  dl {
    margin: 0 0 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  position: relative;
  
  .compact {
    display: flex;
    dl {
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
}