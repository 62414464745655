@import "../../styles/theme";
@import 'src/styles/mixins.scss';

#aino-checkout .UpsaleComponent {
  max-width: 100%;
  border-top: 10px solid $upsaleBackground;
  @media all and (max-width: $mobileBreakpoint) {
    padding-bottom: 50px;
  }

  .products {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: auto;
    width: 100%;
    justify-content: space-between;

    @media all and (max-width: $mobileBreakpoint) {
      flex-wrap: nowrap;
    }
  }

  .product {
    padding: 0;
    appearance: none;
    border: none;
    background: transparent;
    outline: none;
    display: block;
    align-items: flex-start;
    width: 30%;

    @media all and (max-width: $mobileBreakpoint) {
      width: 135px;
      flex-shrink: 0;
    }
    img {
      max-width: 100%;
    }
    cursor: pointer;
    &:hover {
      :local(.addLayer) {
        opacity: 1;
      }
    }
  }

  .productsWrapper {
    @media all and (max-width: $mobileBreakpoint) {
      width: auto;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 0 15px;
      margin: 0 -15px;
      max-width: 100vw;

      @include hideScrollbar;
    }
  }

  .image {
    position: relative;
  }

  .text {
    min-height: 60px;
    font-size: 0.675em;
  }

  .title {
    line-height: 1.3;
    font-weight: 400;
    opacity: 0.6;
    margin: 8px 0 0;
  }

  .price {
    font-weight: 500;
  }

  .mainTitle {
    margin: 20px 0;
    font-size: 0.75em;
  }

  .addLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    opacity: 0;
    color: #000;
    background: rgba(#fff, 0.5);
  }

  .plus {
    font-size: 30px;
    display: block;
  }
}
