@import '../../styles/theme';

#aino-checkout .TotalsComponent {
  margin: 0.5em 0 0;

  .row {
    font-size: 0.66667em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }

  .grandTotal {
    border-top: 1px solid rgba(#000, 0.1);
    padding: 0.75em 0;
    margin-top: 0.75em;
    font-size: 0.875em;
    font-weight: bold;
  }
  .totalsInfo {
    opacity: 0.5;
    margin: 1em 0;
    p {
      font-size: 0.625em;
      line-height: 1.4;
    }
  }
  .removeVoucher {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: inherit;
    outline: none;
    padding: 0;
    text-transform: inherit;
    letter-spacing: inherit;
    text-align: center;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
  }
}
