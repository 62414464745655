#aino-checkout .ButtonComponent {
  .button {
    border: none;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    display: block;
    padding: 0 1em;
    height: 2.45em;
    font-size: 0.75em;
    cursor: pointer;
    white-space: pre-line;
    &:focus {
      outline: none;
      box-shadow: rgba(#000, 0.2) 0 0 0 2px;
    }
    text-align: center;
    position: relative;
  }

  .buttonText {
    position: relative;
    top: -1px;
    white-space: nowrap;
  }

  .pendingText {
    opacity: 0;
  }

  .border {
    border: 1px solid;
  }

  .loader {
    position: absolute;
    width: 100%;
    left: 0;
    top: -2px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .medium {
    font-size: 0.875em;
    height: 2.65em;
    padding: 0 1.2em;
  }

  .large {
    font-size: 1em;
    height: 2.75em;
    padding: 0 1.5em;
  }
}
