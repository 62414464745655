/*
* If you want to change a rating star color you need to customize the next class
* .lipscore-rating-star { fill: green; }
*/

/* Rating star */

.lipscore-rating-star.lipscore-rating-star-inactive {
  fill: #bbbbbb;
}
.lipscore-star-incomplete .lipscore-star-last-half {
  fill: #bbbbbb;
}

/* Common rating widget  */

.lipscore-big-rating-value {
  font-weight: bold;
}
.lipscore-light-rating-value {
  color: #eeeeee;
}
.lipscore-big-rating-votes,
.lipscore-compact-rating-votes {
  font-weight: normal;
  color: #999999;
}
.lipscore-compact-rating-value {
  font-weight: bold;
}
.lipscore-rating-selected-value {
  color: black;
  font-weight: bold;
}

/* Publish review form */

.lipscore-review-login-label,
.lipscore-review-auth-header {
  color: black;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.lipscore-review-postbox {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.lipscore-review-textarea:empty:before {
  color: #bbbbbb;
}
.lipscore-review-textarea-placeholder {
  color: #bbbbbb;
}
.lipscore-review-content-wrapper {
  background-color: #f2f2f2;
  border: 1px solid transparent;
  border-radius: 2px;
}

/* Publish review button */

.lipscore-btn {
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: normal;
}
.lipscore-btn-dark {
  background-color: #666666;
  color: white;
}

/* Review author information */

.lipscore-review-avatar {
  border: 1px solid #e7e7e7;
  border-radius: 2px;
}
.lipscore-strong {
  font-weight: bold;
}
.lipscore-text-muted {
  color: #999999;
}

/* Review publication alerts */

.lipscore-alert {
  border: 1px solid transparent;
  border-radius: 4px;
}
.lipscore-alert-error {
  background-color: #ffb2a1;
}
.lipscore-alert-error > .lipscore-alert-text {
  color: #c1272d;
}
.lipscore-alert-text {
  font-style: italic;
}
.lipscore-alert-success {
  background-color: #78ec89;
}
.lipscore-alert-success > .lipscore-alert-text {
  color: #036805;
}
.lipscore-alert-warning {
  background-color: #eee8a0;
}
.lipscore-alert-warning > .lipscore-alert-text {
  color: #767933;
}

/* Review box in Review list */

.lipscore-review-box {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.lipscore-review-content {
  background-color: #f2f2f2;
}
.lipscore-review-date {
  color: #999999;
}
.lipscore-review-footer {
  background-color: #f2f2f2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #4d4d4d;
}
.lipscore-review-purchase {
  color: #82b548;
}
.lipscore-review-text,
.lipscore-review-reply {
  color: #333333;
}
.lipscore-review-vote-thumb {
  color: #4d4d4d;
}

/* Review reply */

.lipscore-review-reply {
  background-color: #fcfcfc;
  border-radius: 2px;
}
.lipscore-review-reply:before {
  border-right-color: #fcfcfc;
}

/* Review media controls */

.lipscore-review-media-frame {
  border: 1px solid transparent;
  border-radius: 2px;
}
.lipscore-review-media-frame:hover {
  border-color: #777777;
}
.lipscore-review-media-frame-active {
  border-color: #777777;
}
.lipscore-review-media-image {
  border: 0 none;
  border-radius: 2px;
}
.lipscore-review-media-loader {
  border: 1px solid #777777;
  border-radius: 2px;
}

/* Review list paginator */

.lipscore-paginator {
  border-radius: 4px;
}
.lipscore-paginator li a,
.lipscore-paginator li span {
  background-color: white;
  border: 1px solid #dddddd;
  color: #333333;
}
.lipscore-paginator li:first-child a,
.lipscore-paginator li:first-child span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.lipscore-paginator li:last-child a,
.lipscore-paginator li:last-child span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.lipscore-paginator li a:hover,
.lipscore-paginator li span:hover,
.lipscore-paginator li a:focus,
.lipscore-paginator li span:focus {
  background-color: #eeeeee;
}
.lipscore-paginator .active a,
.lipscore-paginator .active span,
.lipscore-paginator .active a:hover,
.lipscore-paginator .active span:hover,
.lipscore-paginator .active a:focus,
.lipscore-paginator .active span:focus {
  background-color: #666666;
  border-color: #666666;
  color: white;
}

/* Service review widgets */

.lipscore-service-review-modal {
  background-color: white;
  font-family: 'Open Sans', sans-serif;
}
.lipscore-service-review-modal .lipscore-modal-header {
  background-color: #f2f2f2;
}
.lipscore-service-review-modal-distribution-title {
  color: black;
  font-weight: bold;
}
.lipscore-service-review-testimonial {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
}

/* Testimonial widget */

.lipscore-testimonial-slider-text {
  font-style: italic;
}
.lipscore-testimonial-slider-tick {
  color: #d8d8d8;
}
.lipscore-testimonial-slider-tick.lipscore-tick-active {
  color: #8b8b8b;
}
.lipscore-testimonial-content .lipscore-icon-quote-left:before {
  color: #f3f3f3;
}
.lipscore-testimonial-user {
  font-weight: bold;
}
.lipscore-testimonial-text {
  font-style: italic;
}

/* Landing page */

.lipscore-reviews-modal-thank {
  color: #82b548;
  font-weight: bold;
}
.lipscore-reviews-modal-hope {
  color: #6e6e6e;
}
.lipscore-reviews-modal-items {
  background: #f7f7f7;
}
.lipscore-reviews-modal-inner-title {
  color: #4a4a4a;
  font-weight: bold;
}
.lipscore-reviews-modal-discount {
  color: #82b548;
  font-weight: bold;
}
.lipscore-reviews-modal-product-name {
  color: #4a4a4a;
  font-weight: bold;
}
.lipscore-reviews-products-wrapper {
  background: #fdfdfd;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.lipscore-product-row {
  border-bottom: 1px solid #e4e4e4;
}

/* Login popover */

.lipscore-login-popover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.lipscore-login-popover-top {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  color: #333333;
  font-weight: bold;
}

/* Dropdowns (user menu, report review menu) */

.lipscore-dropdown-menu {
  background-color: white;
  border: solid #a0a0a0;
  border-radius: 2px;
  border-width: 1px;
}
.lipscore-dropdown-menu-item:hover {
  background-color: #6a95b0;
  color: white !important;
}
.lipscore-dropdown-menu-item:active,
.lipscore-dropdown-menu-item:visited,
.lipscore-dropdown-menu-item:link {
  color: inherit;
  outline: none;
  text-decoration: none;
}
.lipscore-dropdown-toggle:hover {
  color: #777777;
}
.lipscore-dropdown-toggle:hover .lipscore-caret {
  border-top: 4px solid #777777;
}
.lipscore-flags-dropdown {
  color: #4d4d4d;
}
.lipscore-flags-tick {
  color: #4d4d4d;
}
.lipscore-user-dropdown > .lipscore-dropdown-menu {
  color: #4d4d4d;
}
