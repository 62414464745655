@use 'sass:math';

@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  max-width: $drawerMaxWidth;
  background: $white;
  color: $black;
  z-index: 200;

  @include breakpoint('mobile') {
    width: calc(100vw - 32px) !important;
  }
}

.closeIcon {
  position: relative;
  top: -2px;
  @include DefaultActive;
  
  svg {
    transform: rotate(45deg);
  }
}

.productList {
  @include hideScrollbar;
  overflow: scroll;

  @include breakpoint('mobile') {
    padding-bottom: 100px;
  }
}

.button {
  border-top: 1px solid $borderGray;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @include breakpoint("desktop") {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.column {
  width: percentage(math.div(1, 3));
  @include breakpoint('mobile') {
    width: percentage(math.div(1, 2));
  }
}

.title,
.allAdded {
  @include SerifH4;
  @include breakpoint('mobile') {
    font-size: 22px;
  }
}

.allAdded {
  padding: 40px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0%;
  text-align: center;
  width: 100%;
  transform: translate(0, -50%);
}

.remove {
  position: absolute;
  top: 8px;
  right: 8px;
  background: $black;
  border-radius: 50%;
  width: 18px;
  height: 18px;

  path {
    fill: $white;
  }

  &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
  }

  @include breakpoint('desktop') {
    transition: transform 0.26s linear;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.add {
  position: absolute;
  bottom: 12px;
  right: 12px;

  button {
    width: 26px;
    height: 26px;
    border-radius: 4px;

    &:hover {
      background: $mediumGray;
    }
    svg {
      width: 12px;
    }
  }
}

.product {
  position: relative;
  a {
    text-decoration: none;

    &:hover {
      // text-decoration: underline;
    }
  }

  .imageWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: $borderGray;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 125%;
    }
    img {
      @include AbsoluteImage;
    }
  }
  .name,
  .price {
    @include SansSerifText;
    font-size: 14px;
    @include breakpoint('mobile') {
      font-size: 12px;
    }
  }

  .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.button {
  button {
    font-size: 22px;
    @include breakpoint('mobile') {
      height: 48px;
      font-size: 18px;
    }
  }
}
