@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.small {
  background: $white;
  border-radius: 8px;
  @include breakpoint('desktop') {
    svg {
      transition: transform 0.26s linear;
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
}

.spinner {
  position: relative;
  top: 1px;
}

.addToCartButton {
  &:disabled,
  &[disabled] {
    opacity: 0.8;
  }
}
