@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';


.wrapper {
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0px -8px 25px rgba(0, 0, 0, 0.05);
  right: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  max-width: $drawerMaxWidth;
  background: $white;
  color: $black;
  z-index: 200;
  transform: translateX(150%); // default, we will animate with framer motion
  // transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);

  // &.active {
  //   transform: translateX(0);
  // }

  @include breakpoint('mobile') {
    width: calc(100vw - 32px) !important;
  }

  .title {
    @include SerifH4;
  }

  .label {
    @include SansSerifCTA;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
  }
}

.closeIcon {
  position: relative;
  top: -2px;
  @include DefaultActive;
  
  svg {
    transform: rotate(45deg);
  }
}

.selectWrapper {
  position: relative;
  .select {
    border: 1px solid $borderGray;
    background: transparent;
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    height: 64px;
    @include SansSerifCTA;
    text-align: left;
    cursor: pointer;
    letter-spacing: -0.01em;
    font-size: 16px;

    @include breakpoint('mobile') {
      height: 56px;
    }

    &:hover {
      border-color: $darkGray;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: 26px;
    top: 28px;
  }

  .values {
    background: $white;
    z-index: 2;
    position: absolute;
    display: none !important;
    top: 100%;
    left: 0;
    right: 0;
    border-left: 1px solid $borderGray;
    border-bottom: 1px solid $borderGray;
    border-right: 1px solid $borderGray;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: scroll;
    max-height: 320px;

    .value {
      flex-shrink: 0;
      cursor: pointer;

      * {
        cursor: pointer;
      }
      @include SansSerifCTA;
      font-size: 16px;
      &:first-child {
        height: 48px;
        padding-top: 8px;
      }
      &:last-child {
        height: 48px;
        padding-bottom: 8px;
      }
      &:hover {
        background: $darkGray;
      }
    }

    .currency {
      opacity: 0.4;
    }
  }

  &.active {
    .select {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .values {
      display: flex !important;
    }
    svg {
      transform: rotate(180deg);
    }
  }
}
