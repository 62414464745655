@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  width: 100%;

  z-index: 100;
  transform: translateX(-150%);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  overflow: scroll;
  scroll-behavior: smooth;

  @include hideScrollbar;

  top: $topDesktopWithUsp;
  @include breakpoint('mobile') {
    top: $topMobileWithUsp;
  }

  &.active {
    transform: translateX(0);
  }

  @include breakpoint('desktop') {
    display: none !important;
  }
}

body {
  &:global(.no-uspbar) {
    .wrapper {
      top: $topDesktop;
      @include breakpoint('mobile') {
        top: $topMobile;
      }
    }
  }
}

.itemWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .links {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $borderGray;
    }

    a {
      text-decoration: none;
      @include SansSerifText;
      padding: 6px 0;
    }

    .subTitle {
      @include SerifText;
      font-size: 22px;
    }

    .rightColumn {
      border-left: 1px solid $borderGray;
    }
  }

  .button,
  .subButton {
    svg {
      transition: transform 0.18s ease-in-out;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.button {
  @include SansSerifCTA;
  font-size: 20px;
  height: 64px;
  flex-shrink: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid $borderGray;
  width: 100%;
  text-decoration: none;

  &:last-child {
    border-bottom: 1px solid $borderGray;
  }
}

.subButtons {
  border-top: 1px solid $borderGray;
  padding-top: 22px;
}

.subButton {
  @include SansSerifCTA;
  font-size: 20px;
  height: 44px;
  flex-shrink: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;

  @include SansSerifTextSmall;
  text-decoration: none;
  text-transform: uppercase;
}
