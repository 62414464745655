@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.button {
  @include SerifH4;
  font-size: 21px;
  line-height: 1em;
  height: 56px;
  border-radius: 8px;
  padding-top: 2px;
  background: $black;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  color: $white;
  text-align: center;

  @include breakpoint('mobile') {
    height: 48px;
    font-size: 18px;
  }

  &.inverted {
    background: transparent !important;
    color: $black;
    border: 1px solid $borderGray;
    transition: border-color 0.15s;

      &:hover:not(:disabled) {
        border-color: $black !important;
      }
    }

  transition: background 0.1s linear;

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background: #000;
  }
}

body {
  &:global(.theme-green) {
    .button {
      background: $darkGreen;
      color: $white;
    }
  }
}
