#aino-checkout .ChoiceComponent {

  .wrapper {
    border-bottom: 1px solid rgba(#000, 0.1);
    display: block;
  }

  .inner {
    padding: 0.75em 1em 0.75em 24px;
    position: relative;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 50ms ease-out, background-color 150ms ease-out;
    &:hover {
      opacity: 0.6;
    }
    h3 {
      font-size: 1em;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  .selected .inner {
    opacity: 1;
    // background: rgba(#000, 0.04);
  }

  .meta {
    font-size: 0.75em;
    font-weight: normal;
  }

  .content {
    font-size: 0.75em;
    margin-top: 0.25em;
    padding-right: 4em;
    p {
      line-height: 1.5;
    }
  }

  .radio {
    width: 14px;
    height: 14px;
    position: absolute;
    top: calc(1em + 2px);
    // left: 1em;
    left: 0;
    border-radius: 50%;
    border: 1px solid;
    font-size: 1em;
    > span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background:transparent;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: background-color 100ms ease-out;
      &.selectedRadio {
        background: #000;
      }
    } 
  }

  .radioInput {
    position: absolute;
    opacity: 0;
    &:focus ~ .inner {
      opacity: 1;
    }
  }
}