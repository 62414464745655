@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.searchIcon {
  position: relative;
  top: 1px;
  @include breakpoint('desktop') {
    top: 0px;
  }
}

.form {
  position: relative;
  width: 100%;

  @include breakpoint('desktop') {
    padding-bottom: 2px;
  }

  .resetButton {
    position: absolute;
    top: 5px;
    right: 0;
    width: 13px;

    @include breakpoint('mobile') {
      right: 0;
      top: 10px;
    }
  }
}

.input {
  @include SansSerifCTA;
  height: 100%;
  border: none;
  padding-left: 12px;
  padding-top: 3px;
  padding-bottom: 1px;
  width: 100%;
  color: $black;
  appearance: none;
  -webkit-appearance: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  @include breakpoint('mobile') {
    caret-color: #202020;
    padding-top: 9px;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $black;
    opacity: 1; /* Firefox */
  }

  &:focus {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ccc;
      opacity: 1; /* Firefox */
    }
  }
}

.searchResults {
  // Just don’t hide input field
  position: fixed;
  left: 0;
  right: 0;
  overflow: scroll;
  background-color: $white;
  @include hideScrollbar;

  top: $topDesktopWithUsp;
  @include breakpoint('mobile') {
    top: calc(#{$topMobileWithUsp} + 56px);
    bottom: 0;
  }

  &.pending {
    & > div {
      opacity: 0.5;
    }
  }

  &.uspVisible {
    top: 168px;

    @include breakpoint('mobile') {
      top: 144px;
    }
  }
}

body {
  &:global(.no-uspbar) {
    .searchResults {
      top: 132px;
      @include breakpoint('mobile') {
        top: calc(#{$topMobile} + 56px);
      }
    }
  }
}

.pendingWrapper {
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.products {
  @include breakpoint('desktop') {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 0px;
  }
}

.title,
.notFound {
  @include SerifH4;
  font-size: 19px;
  line-height: 1;
}

.viewAll {
  @include CtaWithBorder;
  &:after,
  &:before {
    background: $black;
  }
  position: relative;
  @include SansSerifCTA;
  font-size: 16px;
  text-decoration: none;
  border-bottom: 1px solid $white;
  display: inline-block;
  top: -4px;
  @include breakpoint('mobile') {
    display: none;
  }
}

.borderRight {
  @include breakpoint('desktop') {
    border-right: 1px solid $borderGray;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tag {
  @include SansSerifText;
  height: 35px;
  padding: 0 14px;
  border: 1px solid $black;
  color: $black;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-right: 12px;
  margin-bottom: 12px;
  white-space: nowrap;

  @include breakpoint('desktop') {
    &:hover {
      background: $black;
      color: $white !important;
    }
  }
}
